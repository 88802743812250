<!--
Detail :
  ## 공사현장 단위로 조회 (전체 조회 X)
  ## 안전보건관리비 사용계획이 다 작성되어 제출된 공사현장에 대해서 조회
 *
examples:
 *
-->
<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <template v-if="!unavailable">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <!-- <c-plant type="none" name="plantCd" v-model="searchParam.plantCd" /> -->
            <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
            <!-- <c-select
              :comboItems="plantItems"
              itemText="plantName"
              itemValue="plantCd"
              type="none"
              name="plantCd"
              label="공사현장 (* 사용계획이 제출된 공사현장)"
              v-model="searchParam.plantCd"
            ></c-select> -->
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              type="month"
              default="today"
              label="조회 월(해당 월 기준으로 과거 정보를 취합)"
              name="month"
              v-model="searchParam.month"
            />
          </div>
        </template>
        <template v-else>
          <q-chip class="unavailable-tag" color="red" text-color="white" icon="priority_high" label="사용계획이 제출된 공사현장이 없습니다. 제출 후 이용바랍니다." />
        </template>
      </template>
    </c-search-box>
    <c-card title="공사현장 사용계획 정보" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group v-if="isPlant" outline>
          <c-btn 
            :disabled="unavailable"
            label="사용내역서" 
            icon="print"
            @btnClicked="print" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
        <q-btn-group v-else outline>
          <font style="font-size:0.8em;font-weight:300;" class="blinking" color="#C10015">
            ※ 공사현장관리 메뉴에서 공사현장을 추가하세요.
          </font>
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">
          <c-text
            :disabled="true"
            :editable="editable"
            label="공사기간"
            name="projectPeriod"
            v-model="plant.projectPeriod"
          />
        </div>
        <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">
          <c-text
            :disabled="true"
            :editable="editable"
            type="number"
            label="사업금액(원)"
            name="projectCost"
            v-model="plant.projectCost">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">
          <c-text
            :disabled="true"
            :editable="editable"
            type="number"
            label="안전보건관리비 예산(원)"
            name="projectSafetyCost"
            v-model="plant.projectSafetyCost">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">
          <c-text
            :disabled="true"
            :editable="editable"
            type="number"
            label="누적사용액(원)"
            name="stackAmount"
            v-model="plant.stackAmount">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-8 col-md-4 col-lg-4">
          <c-text
            :disabled="true"
            :editable="editable"
            label="해당 안전보건관리비 사용가능한 업체"
            name="vendorNames"
            v-model="plant.vendorNames">
          </c-text>
        </div>
      </template>
    </c-card>
    <c-table
      ref="table"
      class="q-mt-md"
      title="사용내역서"
      tableId="performanceSMF"
      :columns="grid.columns"
      :data="plant.statementItemUse"
      :columnSetting="false"
      :expandAll="false"
      :isFullScreen="false"
      :usePaging="false"
      :hideBottom="true"
      :filtering="false"
    >
    </c-table>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import selectConfig from '@/js/selectConfig';
export default {
  name: 'statment-item-use',
  data() {
    return {
      searchParam: {
        plantCd: null,
        month: null,
        vendorCd: '',
        innerFlag: '',
      },
      plant: {
        plantCd: null,
        plantName: null,
        projectPeriod: null,
        projectSafetyCost: 0,
        stackAmount: 0,
        projectAddress: '',
        projectCost: 0,
        statementItemUse: [],
      },
      grid: {
        columns: [
          {
            name: 'smfItemName',
            field: 'smfItemName',
            label: '항목',
            align: 'left',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'amountTobeUsed',
            field: 'amountTobeUsed',
            label: '총 예산(A)',
            align: 'right',
            style: 'width:150px',
            sortable: true,
            type: 'cost',
          },
          {
            name: 'monthlyUsage',
            field: 'monthlyUsage',
            label: '당월 사용액',
            align: 'right',
            style: 'width:120px',
            sortable: true,
            type: 'cost',
          },
          {
            name: 'stackAmount',
            field: 'stackAmount',
            label: '누적 사용액(B)',
            align: 'right',
            style: 'width:150px',
            sortable: true,
            type: 'cost',
          },
          {
            name: 'balance',
            field: 'balance',
            label: '잔액(A - B)',
            align: 'right',
            style: 'width:150px',
            sortable: true,
            type: 'cost',
          },
          {
            name: 'rate',
            field: 'rate',
            label: '사용비율(%)',
            align: 'right',
            style: 'width:100px',
            sortable: true,
            type: 'cost',
          },
        ],
        data: [],
      },
      plantItems: [],
      unavailable: false,
      editable: true,
      listUrl: '',
      printUrl: '',
      vendorItems: [],
      isPlant: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.usePlanListUrl = selectConfig.smf.usePlan.list.url
      this.listUrl = selectConfig.smf.statementItemUse.list.url
      this.printUrl = selectConfig.smf.statementItemUse.print.url
      // code setting
      if (!this.$store.getters.user.plantCds) {
        this.isPlant = false;
      }
      // list setting
      this.getList();
    },
    getPlantItems() {
      this.$http.url = this.usePlanListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        smfUsePlanStepCd: 'SUP0000005'
      }
      this.$http.request((_result) => {
        this.plantItems = _result.data;
        if (this.plantItems && this.plantItems.length > 0) {
          if (this.$_.findIndex(this.plantItems, { plantCd: this.$store.getters.user.plantCd }) > -1) {
            this.$set(this.searchParam, 'plantCd', this.$store.getters.user.plantCd);
          } else {
            this.$set(this.searchParam, 'plantCd', this.plantItems[0].plantCd);
          }
          this.unavailable = false;
          this.getList();
        } else {
          this.unavailable = true;
        }
      },);
    },
    getList() {
      if (this.unavailable) return;
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.$_.extend(this.plant, _result.data)
      },);
    },
    print() {
      let thisVue = this;
      this.$http.url = this.printUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request(
        _result => {
          let fileOrgNm = this.plant.plantName + '산업안전보건관리비 사용내역서.docx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
  }
};
</script>
